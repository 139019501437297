import React from 'react';
import styled from 'styled-components';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const Wrapper = styled.div`
  display: flex;
  margin: 0 0 0 34px;

  ${media.xlarge`
    margin: 0 0 0 43px;
  `}
`

const Number = styled.div`
  ${fontstack.default}
  ${type('caption')}
  display: inline-block;
  color: #6A6A6A;
  width: 20px;

  ${media.xlarge`
    ${type('body')}
  `}
`

const Name = styled.h3`
  ${fontstack.default}
  ${type('caption')}
  display: inline-block;
  margin: 0 0 0 10px;
  color: #6A6A6A;

  ${media.xlarge`
    ${type('body')}
  `}
`

const MapListItem = (props:any) => {
  const { number, name} = props;
  return (
    <Wrapper>
      <Number>{number}</Number>
      <Name>{name}</Name>
    </Wrapper>
  )
}

export default MapListItem;