import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from 'react-redux';
import { graphql } from 'gatsby';
import Layout from "../components/Layout/Layout";
import Hero from '../components/Hero/Hero';
import { BlockG, BlockI } from '../components/BlockModule/BlockModule';
import useWindowSize from "../utils/useWindowSize";
import Map from '../components/Map/Map';
import Seo from "../components/Seo/Seo";
import HeroImage from './assets/The Backdrop_image 1@2x.jpg';
import HeroImageBlur from './assets/The Backdrop_image 1@2x_blur.jpg';
import HeroMobileImage from './assets/mobile/The Backdrop_image 1@2x.jpg';
import HeroMobileImageBlur from './assets/mobile/The Backdrop_image 1_blur@2x.jpg';
import { setNavColor, setNavDefaultColor, setNavTrigger, setCurrentPage } from "../actions";
import ImageOne from './assets/The Backdrop_image 3@2x.jpg';
import ImageTwo from './assets/The Backdrop_image 4@2x.jpg';
import ImageThree from './assets/The Backdrop_image 5@2x.jpg';
import MapImage from './assets/The Backdrop_image 6_map@2x.png';
import MapImageMobile from './assets/mobile/The Backdrop_image 6_map_780w.png';
// markup
const BackdropPage = ({data}) => {
  const dispatch = useDispatch();
  const navTriggerRef = useRef();
  const [ Trigger, setTrigger] = useState();
  const [ width, height ] = useWindowSize();
  const document = data.allPrismicBackdrop.edges[0].node.data;
  const blockGData = document.body[0].primary;
  const blockIData = document.body[1].items;
  const MapData = document.body[2];

  console.log(MapData);

  useEffect(() => {
    dispatch(setCurrentPage('/backdrop'));
    setTrigger(navTriggerRef.current);
  },[]);
  return (
    <Layout trigger={Trigger} defaultColor='white' footer>
      <Seo title="Backdrop"/>
        <Hero ref={navTriggerRef} desktop={{src: document.hero_image[0].image.url, placeholder: document.hero_image[0].image.url+'&w=100&blur=10&q=50'}} 
        mobile={{src: document.hero_image[1].image.url, placeholder: document.hero_image[1].image.url+'&w=100&blur=10&q=50'}} small/>
        <BlockG animated 
          title={blockGData.title} 
          html={blockGData.copy.html} />
        <BlockI images={[
            {src: blockIData[0].image.url, placeholder: blockIData[0].image.url+'&w=100&blur=10&q=50' , alt: blockIData[0].image.alt, width: blockIData[0].image.dimensions.width, height: blockIData[0].image.dimensions.height},
            {src: blockIData[1].image.url, placeholder: blockIData[1].image.url+'&w=100&blur=10&q=50' , alt: blockIData[1].image.alt, width: blockIData[1].image.dimensions.width, height: blockIData[1].image.dimensions.height},
            {src: blockIData[2].image.url, placeholder: blockIData[2].image.url+'&w=100&blur=10&q=50' , alt: blockIData[2].image.alt, width: blockIData[2].image.dimensions.width, height: blockIData[2].image.dimensions.height}
          ]} animated/>
        <Map animated mapImage={width > 900 ? MapData.primary.map_image.url : MapData.primary.map_image_mobile.url} locationData={MapData.items} copy={{
  title: MapData.primary.title,
  html: MapData.primary.copy.html
}}/>
    </Layout>
  )
}

export const query = graphql`
query BackdropQuery {
  allPrismicBackdrop {
    edges {
      node {
        data {
          hero_image {
            breakpoint
            image {
              url
              dimensions {
                height
                width
              }
            }
          }
          body {
            ... on PrismicBackdropBodyBlockG {
              id
              primary {
                copy {
                  html
                }
                title
                map_image {
                  alt
                  dimensions {
                    height
                    width
                  }
                  url
                }
              }
            }
            ... on PrismicBackdropBodyBlockI {
              id
              items {
                image {
                  alt
                  url
                  dimensions {
                    height
                    width
                  }
                }
              }
            }
            ... on PrismicBackdropBodyMap {
              id
              items {
                color
                locations
                name
              }
              primary {
                copy {
                  html
                }
                title
                map_image {
                  alt
                  dimensions {
                    height
                    width
                  }
                  url
                }
                map_image_mobile {
                  alt
                  dimensions {
                    height
                    width
                  }
                  url
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export default BackdropPage