import React from 'react';
import styled  from 'styled-components';
import Section from '../Section/Section';
import MapLocationBar from './MapLocationBar';
import { CopyB } from '../CopyModule/CopyModule';
import { media } from '../../utils/mediaQuery';


const MapWrapper = styled.div`
  position: relative;
  width: 100%;
`

const MapImage = styled.img`
  position: relative;
  width: 100%;
  margin: 0 0 60px;
  ${media.medium`
    margin: 0;
  `}
`

const LocationBarWrapper = styled.div`
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
`

const LocationBar = styled.div`
  position: relative;

  ${media.medium`
    position: absolute;
    bottom: 50px;
    right: 50px;
  `}
`

const CopySection = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  ${media.medium`
    top: 50px;
    position: absolute;
    margin: 0;
  `}
`
const CopyWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;
  padding: 0 20px;
  box-sizing: border-box;
`

const CopyWrapperInner = styled.div` 
  grid-column: 1 / span 9;

  ${media.medium`
    grid-column: 1 / span 4;
  `}
`

const AnimateWrapper = styled.div<{animated?:boolean, show?:boolean, delay?:number}>` 

${({ animated, delay }) => animated && `
  transform: translate(0, 100px);
  opacity: 0;
  transition: all .8s ease ${delay ? delay : 0}s; 
`}

${({ show }) => show && `
  transform: translate(0, 0px);
  opacity: 1;
`}

`

const Map = (props:any) => {
  const { mapImage, locationData, copy, animated, show } = props;

  return (
    <MapWrapper>
      {copy ?
      <CopySection>
        <Section none contain>
          <CopyWrapper>
            <CopyWrapperInner>
              <AnimateWrapper animated={animated} show={show}>
                <CopyB title={copy.title}>
                  {copy.html}
                </CopyB>
              </AnimateWrapper>
            </CopyWrapperInner>
          </CopyWrapper>
        </Section>
      </CopySection>
      : null }
      <AnimateWrapper animated={animated} show={show} delay={1}>
        <MapImage src={mapImage} alt="the map" />
      </AnimateWrapper>
      
      { locationData ? 
      <LocationBarWrapper><LocationBar><MapLocationBar animated={animated} show={show} data={locationData}/></LocationBar></LocationBarWrapper> : null }
    </MapWrapper>
  )
}

export default Map;