import React from 'react';
import styled from 'styled-components';
import MapListCategory from './MapListCategory';
import MapListItem from './MapListItem';
import { media } from '../../utils/mediaQuery';
import useWindowSize from '../../utils/useWindowSize';

const Wrapper = styled.div`
  ${media.medium`
    display: block;
  `}
`

const Section = styled.div`
  position: relative;
  margin: 0 0 20px;

  ${media.xlarge`
    margin: 0 0 40px;
  `}
`

const MobileWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const MobileCol = styled.div`
  width: 50%;  
`

const AnimateWrapper = styled.div<{animated?:boolean, show?:boolean, delay?:number}>` 

${({ animated, delay }) => animated && `
  transform: translate(0, 100px);
  opacity: 0;
  transition: all .8s ease ${delay ? delay : 0}s; 
`}

${({ show }) => show && `
  transform: translate(0, 0px);
  opacity: 1;
`}

`
const MapLocationBar = (props:any) => {
  const { data, animated, show } = props;
  let counter = 0;
  let delayCounter = 2;
  const [ width, height ] = useWindowSize();
  return (
    <Wrapper>
    { width > 900 ?
      <>
      { data ? data.map((section) => {
        delayCounter += .2;
        let locations = !Array.isArray(section.locations) ? section.locations.split('|') : section.locations;
        return (
          <Section>
            <AnimateWrapper animated={animated} show={show} delay={delayCounter}>
              <MapListCategory color={section.color} name={section.name}/>
            </AnimateWrapper>
            {locations ? 
              locations.map((location) => {
                counter++;
                delayCounter += .2;
                return (
                  <AnimateWrapper animated={animated} show={show} delay={delayCounter}>
                    <MapListItem number={`${counter}.`} name={location}/>
                  </AnimateWrapper>
                  
                )
              }): null
            }
          </Section>
        )
      }) : null }
      </>
       : <MobileWrapper>
            { data ? data.map((section, index) => {
              let locations = !Array.isArray(section.locations) ? section.locations.split('|') : section.locations;
              delayCounter += .2;
              return (
                <MobileCol>
                  <Section>
                    <AnimateWrapper animated={animated} show={show} delay={delayCounter}>
                      <MapListCategory color={section.color} name={section.name}/>
                    </AnimateWrapper>
                    {locations ? 
                      locations.map((location) => {
                        counter++;
                        delayCounter += .2;
                        return (
                          <AnimateWrapper animated={animated} show={show} delay={delayCounter}>
                            <MapListItem number={`${counter}.`} name={location}/>
                          </AnimateWrapper>
                          
                        )
                      }): null
                    }
                  </Section>
                </MobileCol>

              )
            }) : null }
         </MobileWrapper>}
    </Wrapper>
  )
}

export default MapLocationBar;