import React from 'react';
import styled from 'styled-components';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const Wrapper = styled.div`
  display: flex;
  margin: 0 0 16px;
`

const Circle = styled.div<{color: string}>`
  background-color: ${props => props.color};
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: inline-block;

  ${media.xlarge`
    height: 25px;
    width: 25px;
  `}
`

const Name = styled.h3`
  ${fontstack.default}
  font-size: 14px;
  line-height: 1.25;
  font-weight: 500;
  display: inline-block;
  margin: 0 0 0 18px;

  ${media.xlarge`
    font-size: 20px;
  `}
`

const MapListCategory = (props:any) => {
  const { color, name} = props;
  return (
    <Wrapper>
      <Circle color={color}/>
      <Name>{name}</Name>
    </Wrapper>
  )
}

export default MapListCategory;